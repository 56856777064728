import { Box, Grid, Tooltip, Typography } from '@mui/material';

interface Props {
    title: string;
    phase: number;
    text: string;
    backgroundColor: string;
    translations: any;
}

function VocabPhase(props: Props) {
    return (
        <Grid item xs={4} sm={3} md={11 / 7} lg={11 / 7}>
            <Tooltip
                title={props.translations[('phase' + props.phase + 'Tooltip') as keyof typeof props.translations]}
                placement="bottom"
            >
                <Box
                    sx={{
                        // glass effect without filter blur, because there will be no background
                        backgroundColor: props.backgroundColor,
                        padding: 2,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '100%',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {props.text}
                    </Typography>
                </Box>
            </Tooltip>
        </Grid>
    );
}

export default VocabPhase;
