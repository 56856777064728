import { useEffect, useState } from 'react';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';

type FunctionArguments<T> = T extends (...args: infer A) => any ? A : never;

function useFrequentEndpoint<T extends EmptyResponseType, F extends (...args: any[]) => Promise<T>>(
    hookResponse: any,
    promise: F,
    intervalInSeconds: number,
    ...args: FunctionArguments<F>
): [T, any] {
    const [responseState, setResponseState] = useState<T | EmptyResponseType>(EmptyResponse);

    useEffect(() => {
        if (hookResponse && hookResponse.status !== undefined) {
            setResponseState(hookResponse);
        }

        const fetchData = async () => {
            if (hookResponse.status !== 401 && hookResponse.status !== undefined && hookResponse.status !== 102) {
                const response = await promise(...args);
                setResponseState(response);
            }
        };

        const interval = setInterval(fetchData, intervalInSeconds * 1000);

        return () => clearInterval(interval);
    }, [hookResponse, intervalInSeconds, ...args]);

    return [responseState as T, setResponseState];
}

export default useFrequentEndpoint;
