import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function CreateCourse2EndpointGetHook(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lectionCount: string, 
    param_startWith: string, 
    param_superFast: string, 
    param_model: string, 
) {
    return GetWithHook(routes.CreateCourse, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lectionCount: param_lectionCount, 
        startWith: param_startWith, 
        superFast: param_superFast, 
        model: param_model, 
    });
}

export function CreateCourse2EndpointGetPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lectionCount: string, 
    param_startWith: string, 
    param_superFast: string, 
    param_model: string, 
) {
    return GetWithPromise(routes.CreateCourse, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lectionCount: param_lectionCount, 
        startWith: param_startWith, 
        superFast: param_superFast, 
        model: param_model, 
    });
}

export function CreateCourse2EndpointPostPromise(
    param_baseLanguage: string, 
    param_targetLanguage: string, 
    param_lectionCount: string, 
    param_startWith: string, 
    param_superFast: string, 
    param_model: string, 
) {
    return PostWithPromise(routes.CreateCoursePost, {
        baseLanguage: param_baseLanguage, 
        targetLanguage: param_targetLanguage, 
        lectionCount: param_lectionCount, 
        startWith: param_startWith, 
        superFast: param_superFast, 
        model: param_model, 
    });
}

