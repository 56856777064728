import { theme } from '../generals/theme';

export const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.1px solid ' + theme.palette.primary.light,
    borderRadius: 2,
    paddingTop: 6,
    px: 2,
    paddingBottom: 2,
    width: { xs: '75vw', sm: '50vw', md: 450 },
    maxWidth: '75vw',
    maxHeight: '82vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: 'none',
    zIndex: 10000,
};
