import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { theme } from '../../../generals/theme';

interface Message {
    role: string;
    content: string;
}

function Message(props: { message: Message, index: number, chatbotTitle: string, user: string }) {
    const cleanedMessage = () => props.message.content.replace('/completed', '');

    return (
        <Box
            key={props.index}
            sx={{
                paddingX: 2,
                textAlign: props.message.role === 'assistant' ? 'left' : 'right',
                alignSelf: props.message.role === 'assistant' ? 'flex-start' : 'flex-end',
                backgroundColor: props.message.role === 'assistant' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.1)',
                marginBottom: 2,
                borderRadius: props.message.role === 'assistant'
                    ? '0 16px 16px 16px'
                    : '16px 16px 0 16px',
                maxWidth: '80%',
                marginX: 1,
                marginTop: 4,
                position: 'relative',
                minWidth: 50,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    right: props.message.role === 'assistant' ? 'auto' : 0,
                    left: props.message.role === 'assistant' ? 0 : 'auto',
                    marginTop: -2.5,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: 'black',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {props.message.role === 'assistant' ? props.chatbotTitle : props.user}
                </Typography>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    fontSize: 16,
                    color: props.message.role === 'assistant' ? 'white' : 'black',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                }}
            >
                <ReactMarkdown>
                    {cleanedMessage()}
                </ReactMarkdown>
            </Typography>
        </Box>
    );
}

export default Message;

