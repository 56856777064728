import { HdrAuto, Translate } from '@mui/icons-material';
import { Box, Button, Container, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useIsMobile from '../../constants/useIsMobile';
import { TTS } from '../../generals/speak';
import useEndpoint from '../../generals/useEndpoint';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import LessonResponse from '../../responses/Lesson';
import QuestionsResponse from '../../responses/Questions';
import StateResponse from '../../responses/State';
import { Check2EndpointGetPromise } from '../../routes/Check';
import { Complete2EndpointPostPromise } from '../../routes/Complete';
import { Continue2EndpointPostPromise } from '../../routes/Continue';
import { Language2EndpointGetHook } from '../../routes/Language';
import { Lesson2EndpointGetHook } from '../../routes/Lesson';
import { Questions2EndpointGetHook } from '../../routes/Questions';
import { State2EndpointGetHook } from '../../routes/State';
import AnswerFeedback from '../AnswerFeedback';
import AbortLectionDialog from './AbortLectionDialog';
import ForcedQuestion from './ForcedQuestion';
import ListeningQuestion from './ListeningQuestion';
import MatchingQuestion from './MatchingQuestion';
import MicrophoneQuestion from './MicrophoneQuestion';
import Mission from './Mission';
import Motivation from './Motivation';
import NormalQuestion from './NormalQuestion';
import Notice from './Notice';
import ReverseQuestion from './ReverseQuestion';

interface Props {
    speak: (text: string, language?: string) => void;
    noMenu?: boolean;
}


// Declare that ReactNativeWebView exists on window
declare global {
    interface Window {
        ReactNativeWebView: {
            postMessage: (message: string) => void;
        };
    }
}

function Lection(props: Props) {
    const { baseLanguage, targetLanguage, lection } = useParams();
    const isMobile = useIsMobile();

    useEffect(() => {
        console.log('Currently in lection ' + lection + ' from ' + baseLanguage + ' to ' + targetLanguage);
    }, [baseLanguage, targetLanguage, lection]);

    const [lessonState, setLessonState] = useState<LessonResponse | EmptyResponseType>(EmptyResponse);

    const lessonResponse = Lesson2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '', lection ?? '');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (lessonResponse.data) {
            setLessonState(lessonResponse);
            setIsAdmin(lessonResponse.userState.userModel.role === 'admin');
        }
    }, [lessonResponse.data]);

    const navigate = useNavigate();

    const [translations, setTranslations] = useState({
        answer: '',
        allCorrect: '',
        info: '',
        correct: '',
        wrongCapitalization: '',
        newWord: '',
        mission: '',
        skip: '',
        matching: '',
        almostCorrect: '',
        wrong: '',
        passed: '',
        failed: '',
        listenAndWrite: '',
        finished: '',
        back: '',
        abort: '',
        speak: '',
        speakAndWrite: '',
        translation: '',
        next: '',
        new: '',
        newPhrase: '',
        backToHome: '',
        previousMistake: '',
        editQuestion: '',
        save: '',
        report: '',
        answerShouldBeAccepted: '',
        answerIsWrong: '',
        acceptAsCorrect: '',
        questionIsWrong: '',
        other: '',
        start: '',
        listen: '',
        firstNotice: '',
        repeatNotice: '',
        nowNormalNotice: '',
        finishedLectionNotice: '',
        finish: '',
        continue: '',
        previousMistakeNotice: '',
        finishedLectionNoticeNoMistakes: '',
        weThinkWeHaveEvaluatedYourKnowledgeWeHavePlacedYouInTheAppropriateLection: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
            setNoticeButtonText(translationResponse.data.translations.start);
            setCurrentNotice(translationResponse.data.translations.firstNotice);
        }
    }, [translationResponse.data]);

    const [v2Index, setV2Index] = useState(0);
    const [currentMode, setCurrentMode] = useState<'normal' | 'forced' | 'notice' | 'matching' | 'v2'>('v2');
    const [v2Status, setV2Status] = useState(102);
    const [v2Questions, setV2Questions] = useState<QuestionsResponse['data']['questionsV2'] | []>([]);
    const [v2OriginalCount, setV2OriginalCount] = useState(0);

    const isSupported = false;
    const v2QuestionsResponse = Questions2EndpointGetHook(baseLanguage ?? '', targetLanguage ?? '', lection ?? '', isSupported.toString());
    const [uprankAfterSecondMistake, setUprankAfterSecondMistake] = useState(false);

    useEffect(() => {
        if (v2QuestionsResponse.data) {
            setV2Status(v2QuestionsResponse.status);
            setV2Questions(v2QuestionsResponse.data.questionsV2);
            setV2OriginalCount(v2QuestionsResponse.data.questionsV2.length);
            setUprankAfterSecondMistake(v2QuestionsResponse.data.uprankAfterSecondMistake);
            preloadFor(0);
        }
    }, [v2QuestionsResponse.data]);

    useEffect(() => {
        preloadFor(v2Index);
    }, [v2Index]);

    const preloadFor = (index: number) => {
        if (v2Questions.length > index + 1) {
            /*
             * PreloadTTS(v2Questions[index + 1].data.question);
             * preloadTTS(v2Questions[index + 1].data.answer);
             */

            if (v2Questions[index + 1].data.syllables) {
                for (const syllable of v2Questions[index + 1].data.syllables) {
                    // PreloadTTS(syllable.syllable);
                }
            }
        }
    };

    const v2Next = () => {
        localStorage.removeItem('lastAudioPlayed');
        localStorage.removeItem('lastAudioPlayedText');

        if (v2Index + 1 === v2Questions.length) {
            setFinishedState(true);
            setNotificationOpenState(false);
            setCurrentMode('notice');
            setCurrentNotice(translations.finished);
            setNoticeButtonText(translations.finish);

            const promise = Continue2EndpointPostPromise(baseLanguage ?? '', targetLanguage ?? '', lection ?? '', incorrectState.toString());

            promise.then((response) => {
                if (response.status === 200) {
                    passedAudio.play();
                }
            });

            return;
        }

        setV2Index(v2Index + 1);
        setCurrentAnswer('');
        setSelectedChips([]);
        clearChips();
        setAnswerDisabledState(false);
        setNotificationOpenState(false);
    };

    const [currentNotice, setCurrentNotice] = useState('');
    const [noticeButtonText, setNoticeButtonText] = useState('');

    const [correctState, setCorrectState] = useState(0);
    const [incorrectState, setIncorrectState] = useState(0);

    const [currentAnswer, setCurrentAnswer] = useState('');

    const [notificationOpenState, setNotificationOpenState] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('success');

    const [finishedState, setFinishedState] = useState(false);

    const getPercentage = () => {
        if (finishedState) {
            return 100;
        }

        const progress = v2Index;
        const total = v2Questions.length;

        return Math.round((progress / total) * 100);
    };

    const [answerDisabledState, setAnswerDisabledState] = useState(false);

    const passedAudio = new Audio('/passed.mp3');
    const correctAudio = new Audio('/correct_piano.mp3');
    const incorrectAudio = new Audio('/incorrect_piano.mp3');

    // Add optional bool to allow incorrect capitalization
    const v2Answer = (allowIncorrectCapitalization?: boolean) => {
        const question = v2Questions[v2Index];
        const id = question.data.id;
        setAnswerDisabledState(true);

        localStorage.removeItem('lastAudioPlayed');
        localStorage.removeItem('lastAudioPlayedText');

        if (removeWhitespaceAndPunctuation(currentAnswer)
            === removeWhitespaceAndPunctuation(v2Questions[v2Index].data.answer)) {
            console.log('Locally verified that "' + currentAnswer + '" is correct');
            correctAnswer(1, currentAnswer);
            return;
        } else if (removeWhitespaceAndPunctuation(currentAnswer).toLowerCase()
            === removeWhitespaceAndPunctuation(v2Questions[v2Index].data.answer).toLowerCase()) {
            console.log('Locally verified that "' + currentAnswer + '" is correct, but incorrect capitalization');

            if (allowIncorrectCapitalization) {
                correctAnswer(1, currentAnswer);
                return;
            }

            correctAnswer(2, currentAnswer);
            return;
        }

        console.log('Could not locally verify that "' + currentAnswer + '" is correct, correct would be "' + v2Questions[v2Index].data.answer + '"');
        console.log('Checking with server...');


        const promise = question.type === 'reverseQuestion'
            ? Check2EndpointGetPromise(currentAnswer, question.data.answer)
            : Check2EndpointGetPromise(id, currentAnswer);

        promise.then((response) => {
            if (response.status === 200) {
                if (response.data.correctness === 0) {
                    checkIfRankin();
                    wrongAnswer(v2Questions[v2Index].type === 'reverseQuestion'
                        ? v2Questions[v2Index].data.answer
                        : response.data.answer);
                    console.log('Server verified that "' + currentAnswer + '" is incorrect, correct would be "' + response.data.answer + '"');
                } else {
                    correctAnswer(response.data.correctness, response.data.answer);
                    console.log('Server overrode local verification and verified that "' + currentAnswer + '" is correct');
                }
            }
        }).catch(() => {
        });
    };

    // This function does nothing if in the response .data.uprankAfterSecondMistake is false
    const checkIfRankin = () => {
        if (!uprankAfterSecondMistake) {
            return;
        }

        if (incorrectState === 0) {
            console.log('First mistake');
        }

        if (incorrectState === 1) {
            const promise = Complete2EndpointPostPromise(
                baseLanguage ?? '',
                targetLanguage ?? '',
                v2Questions[v2Index].lection,
            );

            promise.then((response) => {
                setCurrentMode('notice');
                setCurrentNotice(translations.weThinkWeHaveEvaluatedYourKnowledgeWeHavePlacedYouInTheAppropriateLection);
                setNoticeButtonText(translations.continue);
            });
        }
    };

    const removeWhitespaceAndPunctuation = (text: string) => {
        const removeSymbols = [
            '?',
            '!',
            '.',
            ',',
            ';',
            ':',
            '？',
            '！',
            '。',
            '，',
            '；',
            '：',
            '？',
            ' ',
        ];

        for (const symbol of removeSymbols) {
            text = text.replace(symbol, '');
        }

        return text.replace(/\s/g, '');
    };

    const checkSuperCorrect = async (answer: string): Promise<boolean> => {
        const question = v2Questions[v2Index];
        const id = question.data.id;

        if (removeWhitespaceAndPunctuation(answer) === removeWhitespaceAndPunctuation(question.data.answer)) {
            console.log('Locally verified that "' + currentAnswer + '" is correct');
            return true;
        }

        try {
            const response = await Check2EndpointGetPromise(id, answer);

            if (response.status === 200) {
                return response.data.correctness === 1 || response.data.correctness === 2;
            }
            return false;

        } catch (e) {
            return false;
        }
    };

    const [isInPreviousMistakes, setIsInPreviousMistakes] = useState(false);

    const correctAnswer = (
        correctness: number,
        answer: string,
    ) => {
        correctAudio.play();

        if (correctness === 1) {
            const notification = translations.correct.replace('[answer]', answer);

            setNotificationMessage(notification);
            setNotificationSeverity('success');
            setNotificationOpenState(true);
        }

        if (correctness === 2) {
            const notification = translations.wrongCapitalization.replace('[answer]', answer);

            setNotificationMessage(notification);
            setNotificationSeverity('warning');
            setNotificationOpenState(true);
        }

        if (correctness === 3) {
            const notification = translations.almostCorrect.replace('[answer]', answer);

            setNotificationMessage(notification);
            setNotificationSeverity('warning');
            setNotificationOpenState(true);
        }

        setCorrectState(correctState + 1);
    };

    const wrongAnswer = (correct: string) => {
        incorrectAudio.play();

        const incorrect = translations.wrong;

        const correctAnswer = incorrect.replace('[answer]', correct);

        setNotificationMessage(correctAnswer);
        setNotificationSeverity('error');
        setNotificationOpenState(true);

        setIncorrectState(incorrectState + 1);

        const newQuestions = [...v2Questions, v2Questions[v2Index]];

        setV2Questions(newQuestions);

        const newPreviousMistakes = [...previousMistakes, v2Questions[v2Index].data.id];

        setPreviousMistakes(newPreviousMistakes);
    };

    const [selectedChips, setSelectedChips] = useState<string[]>([]);

    const handleAddChip = (chip: string, speak?: boolean) => {
        const newSelected = [...selectedChips, chip];

        setSelectedChips(newSelected);
        setCurrentAnswer(convertChipsToAnswer(newSelected));

        if (speak !== false) {
            TTS(convertChipToSyllable(chip), targetLanguage ?? '');
        }
    };

    /*
     * Uncomment if you want to insta answer forced correct answers
     * useEffect(() => {
     *     if (v2Status === 200 && v2Questions[v2Index].type === 'questionForced') {
     *         console.log(v2Questions[v2Index].type, currentAnswer, v2Questions[v2Index].data.answer);
     *         const isCorrect = checkSuperCorrect(currentAnswer);
     * 
     *         isCorrect.then((correct) => {
     *             if (correct) {
     *                 setTimeout(() => {
     *                     v2Answer();
     *                 }, 1000);
     *             }
     *         });
     *     }
     * }, [currentAnswer]);
     */

    const convertChipToSyllable = (chip: string) => {
        for (const syllable of v2Questions[v2Index].data.syllables) {
            if (chip === syllable.id) {
                return syllable.syllable;
            }
        }

        return '';
    };

    const convertChipsToAnswer = (chips: string[]) => {
        let answer = '';

        for (const chip of chips) {
            answer += convertChipToSyllable(chip) + ' ';
        }

        return answer;
    };

    const clearChips = () => {
        setSelectedChips([]);
        setCurrentAnswer('');
    };

    const handleDeleteChip = (chip: string) => {
        const newSelected = selectedChips.filter((c) => c !== chip);

        setSelectedChips(newSelected);
        setCurrentAnswer(convertChipsToAnswer(newSelected));
    };

    const [previousMistakes, setPreviousMistakes] = useState<string[]>([]);

    const [chipOrTextState, setChipOrTextState] = useState<'chip' | 'text'>('chip');

    const contentPosition = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
    };

    const closeInApp = () => {
        window.ReactNativeWebView?.postMessage('close');
    };

    const updateMode = () => {
        if (v2Index >= v2Questions.length - 1 || uprankAfterSecondMistake) {
            if (props.noMenu === true) {
                closeInApp();

                return;
            }

            navigate('/home');
        } else {
            setCurrentMode('v2');
        }

        if (isInPreviousMistakes) {
            setCurrentMode('v2');
        }
    };

    const [skipState, setSkipState] = useState<number>(0);
    const maxSkips = 2;

    const skipQuestion = () => {
        if (skipState < maxSkips) {
            setSkipState(skipState + 1);
            setIncorrectState(incorrectState + 1);
            v2Next();
        } else {
            v2Answer();
        }
    };

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    if (stateState.status === 200 && stateState.userState.isLogin === false) {
        navigate('/');
    }

    if (stateState.status === 200 && stateState.userState.isLogin === true && stateState.userState.userModel.isVerified === false) {
        navigate('/');
    }

    const [abortDialog, setAbortDialog] = useState(false);

    return (
        <Container maxWidth='sm'>
            {v2Status === 200 &&
                <>
                    <LinearProgress
                        variant='determinate'
                        value={getPercentage()}
                        sx={{
                            height: 10,
                            position: 'absolute',
                            top: 10,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: { xs: '90%', md: '60%' },
                            maxWidth: 600,
                            zIndex: 100,
                            borderRadius: 10,
                        }}
                    />
                    <Box
                        sx={{
                            paddingTop: { xs: 2, md: 2 },
                            paddingBottom: { xs: 2, md: 2 },
                            boxSizing: 'border-box',
                            height: '85vh',
                        }}
                    >
                        {currentMode === 'notice' &&
                            <Notice
                                updateMode={updateMode}
                                currentNotice={currentNotice}
                                noticeButtonText={noticeButtonText}
                                lessonState={lessonState}
                                contentPosition={contentPosition}
                            />
                        }
                        {currentMode === 'v2' &&
                            <>
                                {v2Questions[v2Index].type === 'questionForced' &&
                                    <ForcedQuestion
                                        question={v2Questions[v2Index].data}
                                        index={v2Index}
                                        answer={currentAnswer}
                                        answerTransliteration={v2Questions[v2Index].data.transliteration}
                                        answerDisabledState={answerDisabledState}
                                        setCurrentAnswer={setCurrentAnswer}
                                        handleAnswer={v2Answer}
                                        handleAddChip={handleAddChip}
                                        handleDeleteChip={handleDeleteChip}
                                        setSelectedChips={setSelectedChips}
                                        selectedChips={selectedChips}
                                        chipOrTextState={chipOrTextState}
                                        setChipOrTextState={setChipOrTextState}
                                        baseLanguage={baseLanguage!}
                                        convertChipToSyllable={convertChipToSyllable}
                                        targetLanguage={targetLanguage!}
                                        contentPosition={contentPosition}
                                        translations={translations}
                                        checkCorrect={checkSuperCorrect}
                                        lection={lection!}
                                        isAdmin={isAdmin}
                                    />
                                }
                                {v2Questions[v2Index].type === 'question' &&
                                    <NormalQuestion
                                        question={v2Questions[v2Index].data}
                                        originalCount={v2OriginalCount}
                                        index={v2Index}
                                        answer={currentAnswer}
                                        answerTransliteration={v2Questions[v2Index].data.transliteration}
                                        answerDisabledState={answerDisabledState}
                                        setCurrentAnswer={setCurrentAnswer}
                                        handleAnswer={v2Answer}
                                        handleAddChip={handleAddChip}
                                        handleDeleteChip={handleDeleteChip}
                                        setSelectedChips={setSelectedChips}
                                        selectedChips={selectedChips}
                                        chipOrTextState={chipOrTextState}
                                        setChipOrTextState={setChipOrTextState}
                                        baseLanguage={baseLanguage!}
                                        convertChipToSyllable={convertChipToSyllable}
                                        targetLanguage={targetLanguage!}
                                        speak={props.speak}
                                        contentPosition={contentPosition}
                                        translations={translations}
                                        previousMistakes={previousMistakes}
                                        lection={lection!}
                                        skipQuestion={skipQuestion}
                                        noMenu={props.noMenu === true}
                                        isAdmin={isAdmin}
                                    />
                                }
                                {v2Questions[v2Index].type === 'reverseQuestion' &&
                                    <ReverseQuestion
                                        question={v2Questions[v2Index].data}
                                        index={v2Index}
                                        originalCount={v2OriginalCount}
                                        answer={currentAnswer}
                                        answerTransliteration={v2Questions[v2Index].data.transliteration}
                                        answerDisabledState={answerDisabledState}
                                        setCurrentAnswer={setCurrentAnswer}
                                        handleAnswer={v2Answer}
                                        handleAddChip={handleAddChip}
                                        setSelectedChips={setSelectedChips}
                                        handleDeleteChip={handleDeleteChip}
                                        selectedChips={selectedChips}
                                        chipOrTextState={chipOrTextState}
                                        setChipOrTextState={setChipOrTextState}
                                        baseLanguage={baseLanguage!}
                                        convertChipToSyllable={convertChipToSyllable}
                                        targetLanguage={targetLanguage!}
                                        speak={props.speak}
                                        contentPosition={contentPosition}
                                        translations={translations}
                                        previousMistakes={previousMistakes}
                                        lection={lection!}
                                        skipQuestion={skipQuestion}
                                        noMenu={props.noMenu === true}
                                    />
                                }
                                {v2Questions[v2Index].type === 'listening' &&
                                    <ListeningQuestion
                                        question={v2Questions[v2Index].data}
                                        index={v2Index}
                                        originalCount={v2OriginalCount}
                                        answer={currentAnswer}
                                        answerTransliteration={v2Questions[v2Index].data.transliteration}
                                        answerDisabledState={answerDisabledState}
                                        setCurrentAnswer={setCurrentAnswer}
                                        handleAnswer={v2Answer}
                                        setSelectedChips={setSelectedChips}
                                        handleAddChip={handleAddChip}
                                        handleDeleteChip={handleDeleteChip}
                                        selectedChips={selectedChips}
                                        chipOrTextState={chipOrTextState}
                                        setChipOrTextState={setChipOrTextState}
                                        baseLanguage={baseLanguage!}
                                        convertChipToSyllable={convertChipToSyllable}
                                        targetLanguage={targetLanguage!}
                                        speak={props.speak}
                                        contentPosition={contentPosition}
                                        translations={translations}
                                        previousMistakes={previousMistakes}
                                        lection={lection!}
                                        skipQuestion={skipQuestion}
                                        noMenu={props.noMenu === true}
                                    />
                                }
                                {isSupported && v2Questions[v2Index].type === 'microphone' &&
                                    <MicrophoneQuestion
                                        question={v2Questions[v2Index].data}
                                        index={v2Index}
                                        originalCount={v2OriginalCount}
                                        answer={currentAnswer}
                                        answerDisabledState={answerDisabledState}
                                        setCurrentAnswer={setCurrentAnswer}
                                        handleAnswer={v2Answer}
                                        setSelectedChips={setSelectedChips}
                                        handleAddChip={handleAddChip}
                                        handleDeleteChip={handleDeleteChip}
                                        selectedChips={selectedChips}
                                        chipOrTextState={chipOrTextState}
                                        setChipOrTextState={setChipOrTextState}
                                        baseLanguage={baseLanguage!}
                                        convertChipToSyllable={convertChipToSyllable}
                                        targetLanguage={targetLanguage!}
                                        speak={props.speak}
                                        contentPosition={contentPosition}
                                        translations={translations}
                                        previousMistakes={previousMistakes}
                                        lection={lection!}
                                        v2Next={v2Next}
                                    />
                                }
                                {v2Questions[v2Index].type === 'matchingWord' &&
                                    <MatchingQuestion
                                        currentQuestion={v2Questions[v2Index].data}
                                        next={v2Next}
                                        translations={translations}
                                        baseLanguage={baseLanguage!}
                                        targetLanguage={targetLanguage!}
                                    />
                                }
                                {v2Questions[v2Index].type === 'mission' &&
                                    <Mission
                                        data={v2Questions[v2Index].data}
                                        next={v2Next}
                                        translations={translations}
                                        baseLanguage={baseLanguage!}
                                        targetLanguage={targetLanguage!}
                                    />
                                }
                                {v2Questions[v2Index].type === 'motivation' &&
                                    <Motivation
                                        data={v2Questions[v2Index].data}
                                        next={v2Next}
                                        translations={translations}
                                        baseLanguage={baseLanguage!}
                                        targetLanguage={targetLanguage!}
                                    />
                                }
                            </>
                        }
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '1rem',
                            top: { xs: '2rem', md: 5 },
                        }}
                    >
                        {!finishedState && (currentMode === 'v2') &&
                            <Button
                                variant='text'
                                color='primary'
                                onClick={() => {
                                    setChipOrTextState(chipOrTextState === 'chip' ? 'text' : 'chip');
                                }}
                            >
                                {chipOrTextState === 'chip' ? <Translate /> : <HdrAuto />}
                            </Button>
                        }
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '1rem',
                            top: { xs: '2rem', md: 5 },
                        }}
                    >
                        {!finishedState &&
                            <Button
                                variant='text'
                                color='primary'
                                onClick={() => { props.noMenu === true ? closeInApp() : setAbortDialog(true); }}
                            >
                                {translations.abort}
                            </Button>
                        }
                        <AbortLectionDialog
                            open={abortDialog}
                            close={() => setAbortDialog(false)}
                        />
                    </Box>
                </>
            }
            {v2Status !== 102 && notificationOpenState && currentMode !== 'notice' &&
                <AnswerFeedback
                    questionId={v2Questions[v2Index].data.id}
                    open={notificationOpenState}
                    message={notificationMessage}
                    severity={notificationSeverity}
                    next={v2Next}
                    translations={translations}
                    speak={props.speak}
                    baseLanguage={baseLanguage}
                    targetLanguage={targetLanguage}
                    givenAnswer={currentAnswer}
                    isReverse={v2Questions[v2Index].type === 'reverseQuestion'}
                />
            }
        </Container >
    );
}

export default Lection;
