import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FLAGS } from './constants/flags';
import useIsMobile from './constants/useIsMobile';
import Char from './generals/Char';
import NewLogin from './generals/NewLogin';
import { theme } from './generals/theme';
import ucFirst from './generals/ucFirst';
import AnonResponse from './responses/Anon';
import EmptyResponse from './responses/EmptyResponse';
import EmptyResponseType from './responses/EmptyResponseType';
import { Anon2EndpointGetPromise, Anon2EndpointPostPromise } from './routes/Anon';

interface Props {
    translations: any;
}

interface AlternativeCourseObject {
    baseLanguage: string;
    targetLanguages: string[];
}

function Join(props: Props) {
    const isMobile = useIsMobile();

    const [showLanguages, setShowLanguages] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);

    const [anon, setAnon] = useState<AnonResponse | EmptyResponseType>(EmptyResponse);

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    const [loading, setLoading] = useState<string | null>(null);

    const currentLanguage = localStorage.getItem('languageSet') || 'english';

    const joinCourse = (baseLanguage: string, course: string) => {
        setLoading(course);

        const promise = Anon2EndpointPostPromise(
            baseLanguage,
            course,
        );

        promise.then((response) => {
            response.json().then((data: AnonResponse) => {
                setLoading(null);
                window.location.href = data.data.firstLection;
            });
        });
    };

    const createAnon = () => {
        const promise = Anon2EndpointGetPromise(currentLanguage, '');

        promise.then((response: any) => {
            setAnon(response);
            setShowLanguages(true);
        });
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            {!showLanguages &&
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                        }}
                    >
                        <Char
                            language={currentLanguage}
                            height={isMobile ? '125px' : '300px'}
                            width='auto'
                        />
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', md: '50%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant='h4'
                            align='center'
                            gutterBottom
                        >
                            {props.translations.theEasyWayToLearnALanguage}
                        </Typography>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={createAnon}
                            sx={{
                                px: 8,
                                fontSize: '1.5rem',
                                marginTop: 2,
                            }}
                        >
                            {props.translations.getStarted}
                        </Button>
                        <Button
                            variant='text'
                            color='primary'
                            sx={{
                                marginTop: 2,
                            }}
                            onClick={() => setShowLogin(true)}
                        >
                            {props.translations.ialreadyhaveanaccount}
                        </Button>
                    </Box>
                </Box>
            }
            <NewLogin
                open={showLogin}
                translations={props.translations}
                close={() => setShowLogin(false)}
            />
            {showLanguages &&
                <Box>
                    <Typography
                        variant='h4'
                        align='center'
                        gutterBottom
                    >
                        {props.translations.iWantToLearn}
                    </Typography>
                    {anon.status === 200 &&
                        <Grid
                            container
                            alignItems='center'
                            spacing={2}
                            sx={{
                                marginTop: 4,
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='h6'
                                    align='center'
                                >
                                    {props.translations.baseLanguageColon + ' ' + ucFirst(currentLanguage)}
                                </Typography>
                            </Grid>
                            {anon.data.courses.map((course: string) => (
                                <Grid
                                    item
                                    key={course}
                                    xs={6}
                                    md={3}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: theme.palette.grey[200],
                                            borderRadius: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 2,
                                            gap: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => joinCourse(currentLanguage, course)}
                                    >
                                        {loading === course &&
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <CircularProgress
                                                    color='primary'
                                                    size={70}
                                                />
                                            </Box>
                                        }
                                        {loading !== course &&
                                            <>
                                                <Typography
                                                    variant='h6'
                                                    align='center'
                                                >
                                                    {ucFirst(course)}
                                                </Typography>
                                                {flagExists(course) ?
                                                    <img
                                                        src={'/' + course + '.png'}
                                                        alt={course}
                                                        style={{
                                                            height: 28,
                                                            borderRadius: 5,
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                        }}
                                                    />
                                                    : <Box
                                                        sx={{
                                                            width: 52,
                                                            height: 28,
                                                            borderRadius: '5px',
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                            backgroundColor: theme.palette.grey[300],
                                                        }}
                                                    >
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            ))}
                            {anon.data.courses.length === 0 &&
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant='h6'
                                        align='center'
                                    >
                                        {props.translations.noCoursesAvailableWithThisStartingLanguage}
                                    </Typography>
                                </Grid>
                            }
                            {anon.data.alternativeCourses.length > 0 &&
                                <Typography
                                    variant='h6'
                                    align='center'
                                >
                                    {props.translations.weAlsoHaveTheseCoursesFromOtherBaseLanguages}
                                </Typography>
                            }
                            {anon.data.alternativeCoursesBetter.length > 0 && anon.data.alternativeCoursesBetter.map((course: AlternativeCourseObject) => (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            variant='h6'
                                            align='center'
                                        >
                                            {props.translations.baseLanguageColon + ' ' + ucFirst(course.baseLanguage)}
                                        </Typography>
                                    </Grid>
                                    {course.targetLanguages.map((targetLanguage: string) => (
                                        <Grid
                                            item
                                            xs={6}
                                            md={3}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor: theme.palette.grey[200],
                                                    borderRadius: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: 2,
                                                    gap: 1,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => joinCourse(course.baseLanguage, targetLanguage)}
                                            >
                                                <Typography
                                                    variant='h6'
                                                    align='center'
                                                >
                                                    {ucFirst(targetLanguage)}
                                                </Typography>
                                                {flagExists(targetLanguage) ?
                                                    <img
                                                        src={'/' + targetLanguage + '.png'}
                                                        alt={targetLanguage}
                                                        style={{
                                                            height: 28,
                                                            borderRadius: 5,
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                        }}
                                                    />
                                                    : <Box
                                                        sx={{
                                                            width: 52,
                                                            height: 28,
                                                            borderRadius: '5px',
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                            backgroundColor: theme.palette.grey[300],
                                                        }}
                                                    >
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    ))}
                                </>
                            ))}
                        </Grid>
                    }
                </Box>
            }
        </Box>
    );
}

export default Join;
