
const randomImage = () => {
    const files = [
        '/linguo.png',
        '/mira.png',
    ];

    return files[Math.floor(Math.random() * files.length)];
};
export const getImage = (language: string): string => {
    switch (language) {
        case 'turkish':
            return '/linguo.png';
        case 'english':
            return '/linguo.png';
        case 'german':
            return '/linguo.png';
        case 'spanish':
            return '/linguo.png';
        case 'russian':
            return '/linguo.png';
        case 'italian':
            return '/linguo.png';
        case 'dutch':
            return '/mira.png';
        case 'chinese':
            return '/mira.png';
        default:
            return randomImage();
    }
};
