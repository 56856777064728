export const gettheme = () => {
    const darkMode = localStorage.getItem('darkMode') ?? '0';

    if (localStorage.getItem('darkMode') === null) {
        localStorage.setItem('darkMode', '0');
    }

    return darkMode === '1';
};

export const changetheme = () => {
    const darkMode = localStorage.getItem('darkMode') === '1' ? '0' : '1';
    localStorage.setItem('darkMode', darkMode);

    window.location.reload();
};
