import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TRANSLITERATIONS } from '../../constants/transliterations';
import { TTS } from '../../generals/speak';
import { GetVocabTranslation2EndpointGetPromise } from '../../routes/GetVocabTranslation';
import styles from './QuestionText.module.css';

interface Props {
    question: string;
    questionId: string;
    lection: string;
    direction: 'both' | 'base' | 'target';
    entireTranslation: string;
    entireTransliteration?: string;
    baseLanguage: string;
    language: string;
    showTranslation?: boolean;
    parts: string | null;
}

interface WordWithTranslation {
    word: string;
    translation?: string;
}

const QuestionText: React.FC<Props> = (props: Props) => {
    const [translatedWords, setTranslatedWords] = useState<WordWithTranslation[]>([]);

    useEffect(() => {
        const fetchTranslations = async () => {
            const words = props.question.match(/([\p{L}]+|\s+)/gu);
            if (!words) {
                return;
            }

            if (props.parts) {
                console.log('Using parts for translation:', props.parts);

                const translated = [];

                const parts = JSON.parse(props.parts);

                for (const word of words) {
                    let translation = parts.find((part: any) => part[props.language] === word);

                    if (translation === undefined) {
                        translation = parts.find((part: any) => part[props.language].toLowerCase() === word.toLowerCase());

                        if (translation === undefined) {
                            // reverse
                            translation = parts.find((part: any) => part[props.baseLanguage] === word);
                        }
                    }

                    if (translation) {
                        translated.push({ word, translation: translation[props.baseLanguage] });

                        console.log('Found translation in parts:', word, translation[props.baseLanguage]);
                    } else {
                        console.log('Could not find translation in parts:', word);
                    }
                }

                if (translated.length > 0) {
                    setTranslatedWords(translated);
                    if (translated.length >= words.length) {
                        return;
                    }
                }
            }

            const translationPromises = words.map(async (word) => {
                if (word.trim().length === 0) {
                    return { word };
                }

                try {
                    const response = await GetVocabTranslation2EndpointGetPromise(word, props.lection, props.direction);
                    return { word, translation: response.data.translation };
                } catch {
                    return { word };
                }
            });

            const translations = await Promise.all(translationPromises);
            // Set but add by checking if the word is in the list (only check for the word, not the translation))
            setTranslatedWords((prevWords) => {
                const newWords = [...prevWords];
                for (const translation of translations) {
                    if (!newWords.find((word) => word.word === translation.word)) {
                        newWords.push(translation);
                    }
                }
                return newWords;
            });
        };

        fetchTranslations();
    }, [props.question, props.lection, props.direction]);

    const hasAnyTranslations = translatedWords.some((wordData) => wordData.translation !== undefined);

    const renderTextWithTooltips = () => {
        const textElements = props.question.split(/([\p{L}]+|\s+)/gu);

        if (!hasAnyTranslations) {
            console.log('No translations found');

            return (
                <Tooltip
                    title={props.entireTranslation}
                    arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                >
                    <span
                        className={styles.underlineSpan}
                        onMouseOver={() => {
                            if (props.direction === 'both' || props.direction === 'base') {
                                TTS(props.question, props.language);
                            }
                        }}
                    >
                        {props.question}
                    </span>
                </Tooltip>
            );
        }

        return textElements.map((text, index) => {
            const wordData = translatedWords.find((entry) => entry.word === text);
            if (wordData && wordData.translation) {
                return (
                    <Tooltip
                        key={index}
                        title={wordData.translation}
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                    >
                        <span
                            className={styles.underlineSpan}
                            onMouseOver={() => {
                                if (props.direction === 'both' || props.direction === 'base') {
                                    TTS(text, props.language);
                                }
                            }}
                        >
                            {text}
                        </span>
                    </Tooltip>
                );
            }
            return <span key={index}>{text}</span>; // Return non-translatable text as is
        });
    };

    return (
        <Box>
            <Typography
                variant="h5"
                color="text.primary"
            >
                {renderTextWithTooltips()}
            </Typography>
            {(props.showTranslation || props.direction === 'base') && props.entireTransliteration && (TRANSLITERATIONS.includes(props.language) || props.language === 'chinese') &&
                <Typography
                    variant="body1"
                    color="text.primary"
                >
                    {props.entireTransliteration}
                </Typography>
            }
            {props.showTranslation &&
                <Typography
                    variant="body1"
                    color="text.primary"
                >
                    {props.entireTranslation}
                </Typography>
            }
        </Box>
    );
};

export default QuestionText;
