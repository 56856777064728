import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import NewSignup from './generals/NewSignup';
import useFrequentEndpoint from './generals/useFrequentEndpoint';
import useTranslationState from './generals/useTranslationState';
import ExperienceResponse from './responses/Experience';
import { Experience2EndpointGetHook, Experience2EndpointGetPromise } from './routes/Experience';

function Leaderboard() {
    const [exp, setExp] = useFrequentEndpoint<ExperienceResponse, any>(Experience2EndpointGetHook(), Experience2EndpointGetPromise, 30);

    const translations = useTranslationState({
        leaderboard: '',
        league: '',
        legend: '',
        levelsUpAtTheEndOfTheMonth: '',
        levelsDownAtTheEndOfTheMonth: '',
        you: '',
        daysLeft: '',
        noEntries: '',
        email: '',
        name: '',
        signup: '',
        createAnAccountInOrderToNotLooseYourProgress: '',
        onetimepin: '',
        createAccount: '',
        createAnAccountToShowUpInTheLeaderboard: '',
        thereAreNoDownRanksFromHere: '',
        thereAreNoUpRanksFromHere: '',
        pool: '',
    });

    const getBorderLeft = (index: number) => {
        if (exp.data.levelLeaderboard.length < 20) {
            return 'none';
        }

        if (index < exp.data.levelLeaderboard.length * 0.1 && exp.data.personalLeaderboard.level < 8) {
            return '2px solid ' + useTheme().palette.primary.main;
        }

        if (index >= exp.data.levelLeaderboard.length * 0.9 && exp.data.personalLeaderboard.level > 0) {
            return '2px solid ' + useTheme().palette.error.main;
        }

        return 'none';
    };

    const hasNoAccount = () => {
        if (exp.status !== 200) {
            return false;
        }

        return exp.userState.userModel.role === 'guest';
    };

    const hasAlmostAccountButNotVerified = () => {
        if (exp.status !== 200) {
            return false;
        }

        return exp.userState.userModel.role === 'guest' && exp.userState.userModel.email !== 'anon@lairner.app';
    };

    const getEmail = () => {
        if (exp.status !== 200) {
            return '';
        }

        if (exp.userState.userModel.email === 'anon@lairner.app') {
            return '';
        }

        return exp.userState.userModel.email;
    };

    useEffect(() => {
        if (exp.status === 200) {
            const selfEntry = document.getElementById(exp.userState.userModel.id + 'entry') as HTMLElement;

            setTimeout(() => {
                window.scrollTo({
                    top: selfEntry.offsetTop - 120,
                    behavior: 'smooth',
                });
            }, 300);
        }
    }, [exp.status]);

    return (
        <Box>
            {exp.status === 200 && hasNoAccount() &&
                <NewSignup
                    currentLanguageState={translations.email}
                    translations={translations}
                    askForToken={hasAlmostAccountButNotVerified()}
                    text={translations.createAnAccountToShowUpInTheLeaderboard}
                    email={getEmail()}
                />
            }
            {exp.status === 200 && exp.data.levelLeaderboard.length > 0 &&
                <Container
                    maxWidth="md"
                    sx={{
                        width: '100%',
                        pb: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <Typography
                            variant="h4"
                        >
                            {translations.league.replace('{league}', exp.data.level)} - {translations.pool.replace('{pool}', exp.data.personalLeaderboard.pool + 1)}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {exp.data.levelLeaderboard.map((entry: any, index: number) => (
                            <Grid
                                item
                                key={index}
                                id={entry.user + 'entry'}
                                xs={12}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        backgroundColor: index % 2 === 0
                                            ? useTheme().palette.grey[200]
                                            : useTheme().palette.grey[100],
                                        border: entry.user === exp.userState.userModel.id
                                            ? '1px solid ' + useTheme().palette.primary.main
                                            : '0.1px solid ' + useTheme().palette.grey[300],
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        paddingY: 1,
                                        paddingX: 2,
                                        boxSizing: 'border-box',
                                        borderRadius: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            borderLeft: getBorderLeft(index),
                                            px: 2,
                                        }}
                                    >
                                        {(index < 3 || index >= exp.data.levelLeaderboard.length - 3 || index === 4 || index === 9 || index === 49 || index === 74) &&
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    marginRight: 1,
                                                    color: index === 0
                                                        ? useTheme().palette.warning.dark
                                                        : index === 1
                                                            ? useTheme().palette.grey[500]
                                                            : useTheme().palette.warning.light,
                                                }}
                                            >
                                                {index + 1 + '.'}
                                            </Typography>
                                        }
                                        <Typography
                                            variant="h6"
                                        >
                                            {entry.userData.name}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="h6"
                                    >
                                        {entry.xp}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 4,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            {translations.daysLeft.replace('{days}', exp.data.daysLeft.toString())}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 4,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            {translations.legend}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginRight: 2,
                                marginTop: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    height: 20,
                                    borderLeft: '2px solid ' + useTheme().palette.primary.main,
                                    marginRight: 1,
                                    padding: 1,
                                    display: 'flex',
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                >
                                    {translations.levelsUpAtTheEndOfTheMonth}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                    color="primary"
                                >
                                    {exp.data.personalLeaderboard.level === 8 && '(' + translations.thereAreNoUpRanksFromHere + ')'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    height: 20,
                                    borderLeft: '2px solid ' + useTheme().palette.error.main,
                                    marginRight: 1,
                                    padding: 1,
                                    display: 'flex',
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                >
                                    {translations.levelsDownAtTheEndOfTheMonth}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                    color="error"
                                >
                                    {exp.data.personalLeaderboard.level === 0 && '(' + translations.thereAreNoDownRanksFromHere + ')'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            }
            {exp.status === 200 && exp.data.levelLeaderboard.length === 0 &&
                <Container
                    maxWidth="md"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Typography
                        variant="h4"
                    >
                        {translations.noEntries}
                    </Typography>
                </Container>
            }
        </Box>
    );
}

export default Leaderboard;
