import { useEffect, useState } from 'react';
import { theme } from './theme';

function useIsLgOrLess() {
    const [isLgOrLess, setIsLgOrLess] = useState(window.innerWidth <= theme.breakpoints.values.lg);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= theme.breakpoints.values.lg && !isLgOrLess) {
                setIsLgOrLess(true);
            }
            if (window.innerWidth > theme.breakpoints.values.lg && isLgOrLess) {
                setIsLgOrLess(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isLgOrLess]);

    return isLgOrLess;
}

export default useIsLgOrLess;

