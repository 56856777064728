import { Button } from '@mui/material';
import { useState } from 'react';
import NewLogin from './NewLogin';

interface Props {
    translations: any;
}

function NewLoginButton(props: Props) {
    const [openLogin, setOpenLogin] = useState(false);

    return (
        <>
            <Button
                variant='text'
                color='primary'
                onClick={() => setOpenLogin(true)}
                sx={{
                    marginLeft: 'auto',
                }}
            >
                {props.translations.loginToExistingAccount}
            </Button>
            <NewLogin
                open={openLogin}
                translations={props.translations}
                close={() => setOpenLogin(false)}
            />
        </>
    );
}

export default NewLoginButton;

