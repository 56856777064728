import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function HasSetLanguage2EndpointGetHook(
    param_language: string, 
) {
    return GetWithHook(routes.HasSetLanguage, {
        language: param_language, 
    });
}

export function HasSetLanguage2EndpointGetPromise(
    param_language: string, 
) {
    return GetWithPromise(routes.HasSetLanguage, {
        language: param_language, 
    });
}

export function HasSetLanguage2EndpointPostPromise(
    param_language: string, 
) {
    return PostWithPromise(routes.HasSetLanguagePost, {
        language: param_language, 
    });
}

