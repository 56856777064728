import { Abc, NavigateNextRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FunFact from '../generals/FunFact';
import useIsMobile from '../generals/isMobile';
import NewSignup from '../generals/NewSignup';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import LectionsResponse from '../responses/Lections';
import { Language2EndpointGetHook } from '../routes/Language';
import { Lections2EndpointGetHook, Lections2EndpointGetPromise } from '../routes/Lections';
import CourseSwitcherPlain from './CourseSwitcherPlain';
import CustomCard from './CustomCard';
import FunFactCard from './FunFactCard';
import LectionCard from './LectionCard';
import QuizCard from './QuizCard';

interface Props {
    course: string;
    setCourse: (course: string) => void;
}

function Lections(props: Props) {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [translations, setTranslations] = useState({
        email: '',
        lections: '',
        addLection: '',
        addCourse: '',
        noCoursesAdded: '',
        notAvailable: '',
        difficultyLabel: '',
        quiz: '',
        of: '',
        completed: '',
        locked: '',
        thisLessonIsLocked: '',
        thisLessonIsCompleted: '',
        thisLessonIsAvailable: '',
        recap: '',
        youAreRecappingThisLesson: '',
        moreLections: '',
        moreLectionsSubText: '',
        lettersAndSounds: '',
        exploreLettersAndSounds: '',
        section: '',
        signup: '',
        createAnAccountInOrderToNotLooseYourProgress: '',
        createAccount: '',
        onetimepin: '',
        resendToken: '',
        funFact: '',
        skipToHere: '',
        doAQuickTestAndSkipToHere: '',
        skip: '',
        name: '',
    });

    const translationResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (translationResponse.data) {
            setTranslations(translationResponse.data.translations);
            setIsAdmin(translationResponse.userState.userModel.role === 'admin');
        }
    }, [translationResponse.data]);

    const lastAvailableRef = useRef<null | HTMLDivElement>(null);

    const [lectionsState, setLectionsState] = useState<LectionsResponse | EmptyResponseType>(EmptyResponse);

    const lectionsResponse = Lections2EndpointGetHook(props.course);

    useEffect(() => {
        if (lectionsResponse.data) {
            setLectionsState(lectionsResponse);
        }
    }, [lectionsResponse.data]);

    useEffect(() => {
        if (props.course !== '') {
            Lections2EndpointGetPromise(props.course).then((response) => {
                setLectionsState(response);
            });
        }
    }, [props.course]);

    useEffect(() => {
        if (lastAvailableRef.current) {
            setTimeout(() => {
                const lastAvailableElement = lastAvailableRef.current as HTMLDivElement;

                window.scrollTo({
                    top: (lastAvailableElement?.offsetTop ?? 0) - 100,
                    behavior: 'smooth',
                });
            }, 300);
        }
    }, [lectionsState.data.lections]);

    const hasNoAccount = () => {
        if (lectionsState.status !== 200) {
            return false;
        }

        return lectionsState.userState.userModel.role === 'guest';
    };

    const hasAlmostAccountButNotVerified = () => {
        if (lectionsState.status !== 200) {
            return false;
        }

        return lectionsState.userState.userModel.role === 'guest' && lectionsState.userState.userModel.email !== 'anon@lairner.app';
    };

    const getEmail = () => {
        if (lectionsState.status !== 200) {
            return '';
        }

        if (lectionsState.userState.userModel.email === 'anon@lairner.app') {
            return '';
        }

        return lectionsState.userState.userModel.email;
    };

    const getLastAvailable = () => {
        if (lectionsState.status !== 200) {
            return 0;
        }

        for (let i = lectionsState.data.lections.length - 1; i >= 0; i--) {
            if (lectionsState.data.lections[i].isAvailable) {
                return i;
            }
        }

        return 0;
    };

    return (
        <Box>
            {lectionsState.status === 200 && hasNoAccount() &&
                <NewSignup
                    currentLanguageState={translations.email}
                    translations={translations}
                    askForToken={hasAlmostAccountButNotVerified()}
                    email={getEmail()}
                />
            }
            {lectionsState.status === 404 &&
                <>
                    <Typography variant='h4' gutterBottom>
                        {translations.noCoursesAdded}
                    </Typography>
                    <CourseSwitcherPlain
                        translations={translations}
                        updateCourses={() => { window.location.reload(); }}
                        setCourse={props.setCourse}
                    />
                </>
            }
            {lectionsState.status === 200 && lectionsState.data.lections.length > 0 &&
                <>
                    <Box
                        sx={{
                            marginBottom: 12,
                        }}
                    >
                        {lectionsState.data.letters.length > 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CustomCard
                                    title={translations.lettersAndSounds}
                                    subText={translations.exploreLettersAndSounds}
                                    onClick={() => {
                                        navigate('/alphabet');
                                    }}
                                    media={
                                        <Abc
                                            sx={{
                                                fontSize: 50,
                                                color: 'primary.main',
                                            }}
                                        />
                                    }
                                    translations={translations}
                                />
                            </Box>
                        }
                        {lectionsState.data.lections.map((lection: any, index: number) => {
                            const isLastCompleted = lection.isCompleted &&
                                (index === lectionsState.data.lections.length - 1 ||
                                    !lectionsState.data.lections[index + 1].isCompleted);

                            return (
                                <>
                                    {index % 5 === 0 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 4,
                                            }}
                                        >
                                            <CustomCard
                                                title={translations.section.replace('[section]', (index / 5 + 1).toString())}
                                                subText={translations.lections}
                                                media={
                                                    <Typography
                                                        variant='h3'
                                                        sx={{
                                                            fontSize: 50,
                                                            color: 'primary.main',
                                                        }}
                                                    >
                                                        {index / 5 + 1}
                                                    </Typography>
                                                }
                                                skip={index % 5 === 0 && index !== 0 && getLastAvailable() < index}
                                                onClick={() => {
                                                    if (index % 5 === 0 && index !== 0 && getLastAvailable() < index) {
                                                        navigate(`/courses/${lectionsState.data.baseLanguage}/${lectionsState.data.targetLanguage}/intro/lesson`);
                                                    }
                                                }}
                                                translations={translations}
                                            />
                                        </Box>
                                    }
                                    {isMobile && getLastAvailable() === index &&
                                        <Box
                                            key={lection.id + 'funfact'}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FunFactCard
                                                baseLanguage={lectionsState.data.baseLanguage}
                                                targetLanguage={lectionsState.data.targetLanguage}
                                                translations={translations}
                                            />
                                        </Box>
                                    }
                                    <Box
                                        key={lection.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        ref={isLastCompleted ? lastAvailableRef : null}
                                    >
                                        <LectionCard
                                            key={lection.id}
                                            lection={lection.id}
                                            baseLanguage={lectionsState.data.baseLanguage}
                                            targetLanguage={lectionsState.data.targetLanguage}
                                            isAvailable={lection.isAvailable}
                                            isCompleted={lection.isCompleted}
                                            completion={lection.completion}
                                            level={lection.level}
                                            translations={translations}
                                            parts={lection.parts}
                                        />
                                    </Box>
                                </>
                            );
                        })}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 4,
                            }}
                        >
                            <CustomCard
                                title={translations.moreLections}
                                media={
                                    <NavigateNextRounded
                                        sx={{
                                            fontSize: 50,
                                            color: 'primary.main',
                                        }}
                                    />
                                }
                                subText={translations.moreLectionsSubText}
                                translations={translations}
                            />
                        </Box>
                        {false &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <QuizCard
                                    translations={translations}
                                    baseLanguage={lectionsState.data.baseLanguage}
                                    targetLanguage={lectionsState.data.targetLanguage}
                                    isAvailable={true}
                                />
                            </Box>
                        }
                    </Box>
                </>
            }
            {lectionsState.status === 200 && lectionsState.data.lections.length > 0 && !isMobile &&
                <FunFact
                    baseLanguage={lectionsState.data.baseLanguage}
                    targetLanguage={lectionsState.data.targetLanguage}
                />
            }
        </Box>
    );
}

export default Lections;
