import { Comment, Flag, KeyboardReturn, VolumeUp } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useIsMobile from '../constants/useIsMobile';
import CommentView from './Lection/CommentView';
import ReportDialog from './Report/ReportDialog';
import './AnswerFeedback.css';
import { stopAllAudios } from '../generals/AudioManager';

interface Props {
    questionId?: string;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    open: boolean;
    next: () => void;
    translations: any;
    speak: (text: string, language?: string) => void;
    baseLanguage?: string;
    targetLanguage?: string;
    givenAnswer?: string;
    isReverse?: boolean;
}

function AnswerFeedback(props: Props) {
    const isMobile = useIsMobile();

    const backgroundColors = {
        success: useTheme().palette.success.light, // A softer green background
        info: '#e0faff',    // A softer blue background
        warning: '#fff5cc',  // A softer yellow background
        error: useTheme().palette.error.light,   // A softer red background
    };

    const colors = {
        success: useTheme().palette.success.dark, // A dark green color
        info: '#004466',    // A dark blue color
        warning: '#996600',  // A dark yellow color
        error: useTheme().palette.error.dark,   // A dark red color
    };

    const correctAnswer = props.message.match(/"([^"]+)"/)?.[1];

    const [showComments, setShowComments] = useState(false);

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    const [openReport, setOpenReport] = useState(false);

    const reportQuestion = () => {
        setOpenReport(true);
    };

    const [disabled, setDisabled] = useState(false);

    const isSuccess = props.severity === 'success';

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !disabled) {
                event.preventDefault();
                stopAllAudios();
                props.next();
            }
        };

        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [disabled, props.next]);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1300,
                width: '100vw',
                height: { xs: '20vh', md: '15vh' },
                // MaxHeight: 200,
                display: props.open ? 'flex' : 'none',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: 3,
                gap: 1,
                boxSizing: 'border-box',
                flexDirection: { xs: 'column', md: 'row' },
                backgroundColor: backgroundColors[props.severity],
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: { md: 'absolute', xs: 'relative' },
                    marginTop: { md: 0, xs: 3 },
                    top: { md: '50%', xs: 'auto' },
                    left: { md: 200, xs: 'auto' },
                    transform: { md: 'translateY(-50%)', xs: 'none' },
                }}
            >
                <Box
                    sx={{
                        width: '70px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={isSuccess ? '/check.png' : '/cross.webp'}
                        alt='check'
                        className='animatedImage'
                    />
                </Box>
                <Typography
                    variant='subtitle1'
                    component='div'
                    sx={{
                        color: colors[props.severity],
                    }}
                >
                    {correctAnswer}
                </Typography>
                <Button
                    variant='text'
                    onClick={
                        () => { props.speak(correctAnswer ?? props.translations.allCorrect, props.isReverse ? props.baseLanguage : props.targetLanguage); }
                    }
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <VolumeUp
                    />
                </Button>
            </Box>
            <Button
                variant='contained'
                color='primary'
                sx={{
                    width: isMobile ? 150 : 200,
                    maxWidth: '60vw',
                    position: { xs: 'relative', md: 'absolute' },
                    top: { md: '50%', xs: 'auto' },
                    right: { md: 200, xs: 'auto' },
                    transform: { md: 'translateY(-50%)', xs: 'none' },
                }}
                onClick={() => {
                    setDisabled(true);
                    stopAllAudios();
                    props.next();
                    setTimeout(() => {
                        setDisabled(false);
                    }, 500);
                }}
                disabled={disabled}
            >
                {props.translations.next}
                {!isMobile &&
                    <KeyboardReturn
                        sx={{
                            ml: 1,
                        }}
                    />
                }
            </Button>
            <Box
                sx={{
                    position: 'absolute',
                    paddingY: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <>
                    {showComments && props.questionId &&
                        <CommentView
                            questionId={props.questionId}
                            show={showComments}
                            close={toggleComments}
                        />
                    }
                </>
                <Button
                    variant='text'
                    onClick={toggleComments}
                    sx={{
                        cursor: 'not-allowed',
                    }}
                >
                    <Comment />
                </Button>
                {props.questionId &&
                    <>
                        <IconButton
                            onClick={reportQuestion}
                            color='primary'
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <Flag />
                        </IconButton>
                    </>
                }
            </Box>
            {
                props.questionId &&
                <ReportDialog
                    open={openReport}
                    setOpen={setOpenReport}
                    translations={props.translations}
                    question={props.questionId}
                    answer={props.givenAnswer ?? ''}
                />
            }
        </Box >
    );
}

export default AnswerFeedback;
