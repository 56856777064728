import { Container } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lections from './courses/Lections';
import useEndpoint from './generals/useEndpoint';
import StateResponse from './responses/State';
import { State2EndpointGetHook } from './routes/State';

function Home() {
    const navigate = useNavigate();

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    if (stateState.status === 200 && stateState.userState.isLogin === false) {
        navigate('/');
    }

    if (stateState.status === 200 && stateState.userState.isLogin === true && stateState.userState.userModel.isVerified === false) {
        navigate('/');
    }

    const [course, setCourse] = useState(localStorage.getItem('course') || '');

    return (
        <Container maxWidth='xl' sx={{ paddingTop: 1 }}>
            {stateState.status === 200 && stateState.userState.isLogin === true &&
                <>
                    <Lections
                        course={course}
                        setCourse={setCourse}
                    />
                </>
            }
        </Container>
    );
}

export default Home;
