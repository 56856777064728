import { Box } from '@mui/material';
import { theme } from '../../generals/theme';
import SelectChip from './SelectChip';

interface Props {
    selectedChips: string[];
    convertChipToSyllable: (id: string) => string;
    setSelectedChips?: (chips: string[]) => void;
    handleDeleteChip?: (id: string) => void;
    handleAddChip?: (id: string) => void;
    deletable?: boolean;
    draggable?: boolean;
    showPinYin?: boolean;
    language: string;
}

function SelectedChips(props: Props) {
    const getLetterForIndexLowerCase = (num: number) => String.fromCharCode(97 + num);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                borderTop: '0.1px solid ' + theme.palette.divider,
                borderBottom: '0.1px solid ' + theme.palette.divider,
                py: 1,
                marginTop: 1,
                minHeight: 57.5,
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
            }}
            onDrop={(e) => {
                e.preventDefault();

                if (props.setSelectedChips) {
                    const chipId = localStorage.getItem('draggedChip');

                    if (chipId && props.handleAddChip && !props.selectedChips.includes(chipId)) {
                        props.handleAddChip(chipId);
                    } else if (chipId && props.handleDeleteChip && props.selectedChips.includes(chipId)) {
                        props.handleDeleteChip(chipId);
                    }
                }
            }}
        >
            {!props.deletable && props.selectedChips.map((chip) => (
                <SelectChip
                    id={chip}
                    text={props.convertChipToSyllable(chip)}
                    onDragStart={(e: any) => {
                        if (props.draggable) {
                            localStorage.setItem('draggedChip', chip);
                        }
                    }}
                    selected
                    hotKey={getLetterForIndexLowerCase(props.selectedChips.indexOf(chip))}
                    draggable={props.draggable !== false}
                    language={props.language}
                    showPinYin={props.showPinYin}
                />
            ))}
            {props.deletable && props.selectedChips.map((chip) => (
                <SelectChip
                    id={chip}
                    onDelete={() => {
                        if (props.handleDeleteChip) {
                            props.handleDeleteChip(chip);
                        }
                    }}
                    text={props.convertChipToSyllable(chip)}
                    onDragStart={(e: any) => {
                        if (props.draggable) {
                            localStorage.setItem('draggedChip', chip);
                        }
                    }}
                    selected
                    language={props.language}
                    hotKey={getLetterForIndexLowerCase(props.selectedChips.indexOf(chip))}
                    draggable={props.draggable !== false}
                    showPinYin={props.showPinYin}
                />
            ))}
        </Box>
    );
}

export default SelectedChips;
