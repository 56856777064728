import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function EditQuestion2EndpointGetHook(
    param_questionId: string, 
    param_question: string, 
    param_primaryAnswer: string, 
) {
    return GetWithHook(routes.EditQuestion, {
        questionId: param_questionId, 
        question: param_question, 
        primaryAnswer: param_primaryAnswer, 
    });
}

export function EditQuestion2EndpointGetPromise(
    param_questionId: string, 
    param_question: string, 
    param_primaryAnswer: string, 
) {
    return GetWithPromise(routes.EditQuestion, {
        questionId: param_questionId, 
        question: param_question, 
        primaryAnswer: param_primaryAnswer, 
    });
}

export function EditQuestion2EndpointPostPromise(
    param_questionId: string, 
    param_question: string, 
    param_primaryAnswer: string, 
) {
    return PostWithPromise(routes.EditQuestionPost, {
        questionId: param_questionId, 
        question: param_question, 
        primaryAnswer: param_primaryAnswer, 
    });
}

