import { Box, Typography, useTheme } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import FunFactResponse from '../responses/FunFact';
import { FunFact2EndpointGetHook, FunFact2EndpointGetPromise } from '../routes/FunFact';
import Char from './Char';
import useFrequentEndpoint from './useFrequentEndpoint';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
}

function FunFact(props: Props) {
    const [funFact, setFunFact] = useFrequentEndpoint<FunFactResponse, any>(FunFact2EndpointGetHook(props.baseLanguage, props.targetLanguage), FunFact2EndpointGetPromise, 60, props.baseLanguage, props.targetLanguage);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 20,
                right: 10,
            }}
        >
            {funFact.status === 200 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',
                    }}
                >
                    <Box
                        sx={{
                            padding: 2,
                            width: '16vw',
                            backgroundColor: useTheme().palette.grey[200],
                            borderRadius: 2,
                            // Add a triangle to the right of the box
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                bottom: 40,
                                right: '-10px',
                                transform: 'translateY(-50%)',
                                width: 0,
                                height: 0,
                                borderLeft: '10px solid ' + useTheme().palette.grey[200],
                                borderTop: '10px solid transparent',
                                borderBottom: '10px solid transparent',
                            },
                        }}
                    >
                        <Typography
                            variant='body2'
                        >
                            <ReactMarkdown>
                                {funFact.data.fact}
                            </ReactMarkdown>
                        </Typography>
                    </Box>
                    <Char
                        width='100px'
                        height='100px'
                        language={props.targetLanguage}
                    />
                </Box>
            }
        </Box>
    );
}

export default FunFact;
