import CustomCard from './CustomCard';
import { FunFact2EndpointGetHook, FunFact2EndpointGetPromise } from '../routes/FunFact';
import useFrequentEndpoint from '../generals/useFrequentEndpoint';
import FunFactResponse from '../responses/FunFact';
import { Box } from '@mui/material';
import Char from '../generals/Char';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
    translations: any;
}

function FunFactCard(props: Props) {
    const [funFact, setFunFact] = useFrequentEndpoint<FunFactResponse, any>(FunFact2EndpointGetHook(props.baseLanguage, props.targetLanguage), FunFact2EndpointGetPromise, 60, props.baseLanguage, props.targetLanguage);

    return (
        <Box>
            <CustomCard
                title={props.translations.funFact}
                subText={funFact.data.fact}
                translations={props.translations}
                media={
                    <Char
                        width='100px'
                        height='100px'
                        language={props.targetLanguage}
                    />
                }
            />
        </Box>
    );
}

export default FunFactCard;
