import { Add } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, useTheme } from '@mui/material';
import { useState } from 'react';
import { CreateCourse2EndpointPostPromise } from '../routes/CreateCourse';
import LairnerModal from './LairnerModal';

function CreateCourse() {
    const [open, setOpen] = useState(false);

    const [baseLanguage, setBaseLanguage] = useState('english');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [lectionCount, setLectionCount] = useState(5);
    const [model, setModel] = useState('gpt');

    const createCourse = () => {
        const promise = CreateCourse2EndpointPostPromise(
            baseLanguage,
            targetLanguage,
            lectionCount.toString(),
            '0',
            'false',
            model,
        );

        promise.then((response) => {
            if (response.status === 200) {
                setOpen(false);
                window.location.reload();
            }
        });
    };

    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
            >
                <Add
                    sx={{
                        color: useTheme().palette.text.primary,
                        fontSize: 15,
                    }}
                />
            </IconButton>
            <LairnerModal
                open={open}
                close={() => setOpen(false)}
                title="Create Course"
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Base Language"
                        value={baseLanguage}
                        onChange={(event) => setBaseLanguage(event.target.value)}

                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Target Language"
                        value={targetLanguage}
                        onChange={(event) => setTargetLanguage(event.target.value)}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <TextField
                        label="Lection Count"
                        value={lectionCount}
                        type="number"
                        onChange={(event) => setLectionCount(parseInt(event.target.value))}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <RadioGroup
                        value={model}
                        onChange={(event) => setModel(event.target.value)}
                    >
                        <FormControlLabel
                            value="gpt"
                            control={<Radio />}
                            label="GPT-4o-mini"
                        />
                        <FormControlLabel
                            value="command"
                            control={<Radio />}
                            label="Command"
                        />
                        <FormControlLabel
                            value="gptsuper"
                            control={<Radio />}
                            label="GPT-4o"
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    <Button
                        onClick={createCourse}
                        color="primary"
                        variant="contained"
                    >
                        <Add />
                    </Button>
                </FormControl>
            </LairnerModal>
        </>
    );
}

export default CreateCourse;
