import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LairnerModal from '../../generals/LairnerModal';
import useTranslationState from '../../generals/useTranslationState';

interface Props {
    open: boolean;
    close: () => void;
}

function AbortLectionDialog(props: Props) {
    const navigate = useNavigate();

    const translationState = useTranslationState({
        abortLection: '',
        areYouSureYouWantToAbortAndLoseProgress: '',
    });

    return (
        <LairnerModal
            open={props.open}
            close={props.close}
            title={translationState.abortLection}
        >
            <Typography
                variant='body1'
            >
                {translationState.areYouSureYouWantToAbortAndLoseProgress}
            </Typography>
            <Button
                variant='contained'
                color='error'
                onClick={() => {
                    navigate('/home');
                }}
                sx={{
                    marginTop: 2,
                }}
            >
                {translationState.abortLection}
            </Button>
        </LairnerModal>
    );
}

export default AbortLectionDialog;
