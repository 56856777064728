import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function AddCustom2EndpointGetHook(
    param_baseWord: string, 
    param_targetWord: string, 
    param_transliteration: string, 
    param_course: string, 
) {
    return GetWithHook(routes.AddCustom, {
        baseWord: param_baseWord, 
        targetWord: param_targetWord, 
        transliteration: param_transliteration, 
        course: param_course, 
    });
}

export function AddCustom2EndpointGetPromise(
    param_baseWord: string, 
    param_targetWord: string, 
    param_transliteration: string, 
    param_course: string, 
) {
    return GetWithPromise(routes.AddCustom, {
        baseWord: param_baseWord, 
        targetWord: param_targetWord, 
        transliteration: param_transliteration, 
        course: param_course, 
    });
}

export function AddCustom2EndpointPostPromise(
    param_baseWord: string, 
    param_targetWord: string, 
    param_transliteration: string, 
    param_course: string, 
) {
    return PostWithPromise(routes.AddCustomPost, {
        baseWord: param_baseWord, 
        targetWord: param_targetWord, 
        transliteration: param_transliteration, 
        course: param_course, 
    });
}

